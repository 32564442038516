a:hover {
    color: #fff;
    text-decoration: none;
}

.gallery-parent {
    max-width: 2000px;
    grid-auto-rows: minmax(350px, auto);
    margin: 0 100px 0 100px;
}

/* medium to small screens */
@media screen and (max-width: 992px) {
    .gallery-parent {
        margin: 0 15px 0 15px;
    }
}
