.link:link {
    color: #333;
    text-decoration: underline;
}

.link:visited {
    color: #333;
    text-decoration: underline;
}

.blurb {
    display: flex;
    justify-content: center;
    margin: 0px 200px;
}

.meImage {
  float: left;
  width: 30%
}

.meImage img {
    width: 100%;
}

.meBlurb {
    width: 60%;
    padding-left: 150px;
    float:left;
    font-size: 18px;
    text-align: justify;
}

/* on Mobile */
@media (max-width: 1200px) {
    .blurb {
        margin: 0px 15px;
    }
    .meBlurb {
        padding-top: 24px;
        padding-left: 30px;
    }
}

@media screen and (max-width: 992px) {
    .blurb {
        margin: 0px 15px;
    }
    .meBlurb {
        padding-left: 30px;
    }
}

@media (max-width: 768px) {
    .blurb {
        display: inline-block;
        margin: 0px 15px;
    }
    .meImage {
        width: 100%;
    }
    .meImage img {
        width: 100%;
    }
    .meBlurb {
        padding-left: 0;
        width: 100%;
    }
}
