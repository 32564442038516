/********************* Colour reference chart****************
*************************** comment ********* colour ******** 

red text colour             rt               #F20500
mobile nav bg color         navbg            rgba(128,128,128,0.2)             
*/
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');

@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }

/* responsive text queries */
@media screen and (max-width: 992px) {
  p {
    font-size: 95%;
  }
  /* p,.btn,input,div,span,h4 {
    font-size: 95%;
  }
  h1 {
    font-size: 24px;  
  }
  h2 {
    font-size: 22px;  
  }
  h3 {
    font-size: 18px;  
  }  */
}

@media screen and (max-width: 768px) {
  p {
    font-size: 90%;
  }
  /* h1 {
    font-size: 20px;  
  }
  h2 {
    font-size: 18px;  
  }
  h3 {
    font-size: 16px;  
  } */
}

@media (min-width: 1200px) {
    .container{
        max-width: 990px; /*970*/
    }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: .95em;
}

::-webkit-scrollbar-track {
  background-color: rgba(217, 217, 217, 0.5);
}

::-webkit-scrollbar-thumb {
  background: rgba(184, 184, 184, 0.5);
  box-shadow: inset 0.05em 0.05em 0 rgba(0, 0, 0, 0.1), inset 0 -0.05em 0 rgba(0, 0, 0, 0.07);
}

.content {
  font: 1em 'Crimson Text', 'Lato', sans-serif;
  margin: 1em 3em 1em 3em;
  text-align: center;
  transition: margin .3s ease-in-out;
}
.content > p {
  margin: .25em 0 .75em 0;
}

.innerContent {
  margin: 0 140px 0 140px;
}

body {
  font-family: 'Crimson Text', sans-serif;
}

/* Header */
.header{
  position: relative;
  top: 0;
  padding: 40px 40px 0px 40px;
  width: 100%;
}

/* footer */

footer{
  position: relative;
  margin: 80px 80px 50px 80px;
}

.lightboxGrid {
  object-fit: cover;
  margin: 6px;
  width: 300px;
  height: 300px;
}

/* small screens */
@media screen and (max-width: 44em) {
  html,
  body {
    margin: 0;
    overflow-x: hidden;
  }

  .content {
    margin: 2.5em 0em 0 0em;
  }

  .innerContent {
    margin: 15px;
  }

  .nav {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  footer{
    margin: 50px 10px 50px 10px;
  }

  .lightboxGrid {
    width: 100%;
    height: auto;
    display: block;
    margin: 12px 0px 12px 0px;
  }
}

@media (max-height:800px){
    footer { position: static; }
    header { padding-top:40px; }
}
